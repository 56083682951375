import { AlFilterModel } from '@/components/filter-builder/models/AlFilterModel';
import AlGrid, { DEFAULT_GRID_OPTIONS, GRID_DEFAULT_SORTING_ORDER } from '@/components/grid/AlGrid';
import ChangePercentageCellRenderer from '@/components/grid/cells/ChangePercentageCellRenderer';
import LinkCallbackCellRenderer, { ILinkCallbackCellRendererParams } from '@/components/grid/cells/LinkCallbackCellRenderer';
import { ColumnId } from '@/components/grid/columns/columns.enum';
import ChangePercentageHeaderRenderer from '@/components/grid/headers/ChangePercentageHeaderRenderer';
import DefaultHeaderRenderer from '@/components/grid/headers/DefaultHeaderRenderer';
import useColDefsFunctions from '@/components/grid/hooks/useColDefsFunctions';
import useColumnTypes from '@/components/grid/hooks/useColumnTypes';
import useComparisonUnitColumnData from '@/components/grid/hooks/useComparisonUnitColumnData';
import { ComparisonUnit, ExpandedGridContext } from '@/components/grid/types';
import { MetricAggregates } from '@/components/metrics/types/MetricField';
import { useGridColumnState } from '@/hooks/useGridColumnState';
import { sleep } from '@/lib/api/api-utils';
import { profilesStatsTableDefaultColumnState } from '@/modules/profiles/configuration/profiles-stats-table.default-column-state';
import { ProfileWithMetricsModel } from '@/modules/profiles/types/ProfileWithMetricsModel';
import { useActiveTeamContext } from '@/modules/teams/contexts/ActiveTeamContext';
import { UserSettingKey, useUserContext } from '@/modules/users';
import { CurrencyCode } from '@/modules/users/types/CurrencyCode';
import { Routes } from '@/router/router-paths';
import { GridReadyEvent, IHeaderParams, IRowNode, ValueFormatterParams } from 'ag-grid-community';
import { ColDef, GridApi, GridOptions, ICellRendererParams, ValueGetterParams } from 'ag-grid-enterprise';
import { FunctionComponent, useEffect, useMemo, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import useProfileStatsMetricColumnAggregates from '../hooks/useProfileStatsMetricColumnAggregates';
import { Environment } from '@/config/Environment';

interface ProfilesStatsGridContext extends ExpandedGridContext {
  metricColumnAggregates: MetricAggregates | undefined;
}

interface ProfilesStatsTableProps {
  onGridReadyCallback?: (params: GridReadyEvent) => void;
  rowData: ProfileWithMetricsModel[] | undefined;
  isFetching: boolean;
  externalFilters: AlFilterModel[];
  comparisonUnit: ComparisonUnit;
}

const AGGREGATE_CURRENCY_CODE = CurrencyCode.USD; // Not dynamic because not in context

const ProfilesStatsTable: FunctionComponent<ProfilesStatsTableProps> = ({
  onGridReadyCallback,
  rowData,
  isFetching,
  externalFilters,
  comparisonUnit: externalComparisonUnit,
}) => {
  const navigate = useNavigate();
  const { setActiveTeam } = useActiveTeamContext();
  const { user } = useUserContext();

  const hasVendorProfiles = useMemo(() => {
    return user?.teams.some((team) => team.profiles.some((profile) => profile.isVendor));
  }, [user]);

  const hasSellerProfiles = useMemo(() => {
    return user?.teams.some((team) => team.profiles.some((profile) => profile.isSeller));
  }, [user]);

  const gridApiRef = useRef<GridApi<ProfileWithMetricsModel>>();
  const gridContextRef = useRef<ProfilesStatsGridContext>();

  const { setColumnStateGridApi, handleColumnStateChange, applyStateToDefinitions, setIsAutoSaveEnabled } = useGridColumnState(
    UserSettingKey.PROFILES_STATS_TABLE_COLUMN_STATE,
    profilesStatsTableDefaultColumnState(),
  );

  const { comparisonUnit } = useComparisonUnitColumnData<ProfileWithMetricsModel>({
    gridApiRef,
    gridContextRef,
    comparisonUnit: externalComparisonUnit,
  });

  const { getChangePercentageHeaderRendererParams } = useColumnTypes();
  const { metricsDataComparator, getMetricFieldChangePercentageCellRendererParams } = useColDefsFunctions();

  const { metricColumnAggregates, onGridReadyForMetricColumnAggregates, updateContextAggregates } = useProfileStatsMetricColumnAggregates({
    gridApiRef,
    gridContextRef,
    aggDataCurrencyCode: AGGREGATE_CURRENCY_CODE,
  });

  async function onProfileNameClicked(profileWithMetrics: ProfileWithMetricsModel) {
    try {
      if (!profileWithMetrics.id) {
        return;
      }
      setActiveTeam({ teamId: profileWithMetrics.teamId, profileId: profileWithMetrics.id });
      await sleep(50); // wait for 50 ms before navigate
      navigate(Routes.OPTIMIZER);
    } catch (error) {
      console.log(error);
      toast.error('Unable to navigate to profile. Please try again later.');
    }
  }

  const columnDefs: ColDef<ProfileWithMetricsModel>[] = useMemo(() => {
    const colDefs: ColDef<ProfileWithMetricsModel>[] = [
      {
        colId: ColumnId.TEAM_NAME,
        headerName: 'Team',
        field: 'teamName',
        minWidth: 150,
      },
      {
        colId: ColumnId.PROFILE_NAME,
        headerName: 'Profile',
        minWidth: 150,
        cellRendererSelector(params: ICellRendererParams<ProfileWithMetricsModel>) {
          if (params.node.isRowPinned()) {
            return {
              component: () => <div className="h-full flex items-center">{params.data?.name}</div>,
            };
          }
        },
        cellRenderer: LinkCallbackCellRenderer,
        cellRendererParams: (
          params: ICellRendererParams<ProfileWithMetricsModel>,
        ): ILinkCallbackCellRendererParams<ProfileWithMetricsModel> => {
          return {
            buttonText: params.data?.nameWithMarket || '',
            tooltip: 'Start optimizing this Profile',
            callback: onProfileNameClicked,
          };
        },
        valueGetter: (params: ValueGetterParams<ProfileWithMetricsModel>) => (params.data ? params.data.nameWithMarket : null),
      },
      {
        colId: ColumnId.IMPRESSIONS,
        headerName: 'Impressions',
        field: 'impressions',
        type: 'profileStatsMetricColType',
      },
      {
        colId: ColumnId.CLICKS,
        headerName: 'Clicks',
        field: 'clicks',
        type: 'profileStatsMetricColType',
      },
      {
        colId: ColumnId.ORDERS,
        headerName: 'Orders',
        field: 'orders',
        type: 'profileStatsMetricColType',
      },
      {
        colId: ColumnId.CTR,
        headerName: 'CTR',
        field: 'ctr',
        type: 'profileStatsMetricColType',
      },
      {
        colId: ColumnId.CVR,
        headerName: 'CVR',
        field: 'cvr',
        type: 'profileStatsMetricColType',
      },
      {
        colId: ColumnId.CPC,
        headerName: 'CPC',
        field: 'cpc',
        type: 'profileStatsMetricColType',
      },
      {
        colId: ColumnId.SPEND,
        headerName: 'Spend',
        field: 'spend',
        type: 'profileStatsMetricColType',
      },
      {
        colId: ColumnId.SALES,
        headerName: 'Sales',
        field: 'sales',
        type: 'profileStatsMetricColType',
      },
      {
        colId: ColumnId.ACOS,
        headerName: 'ACOS',
        field: 'acos',
        type: 'profileStatsMetricColType',
      },
      {
        colId: ColumnId.ROAS,
        headerName: 'ROAS',
        field: 'roas',
        type: 'profileStatsMetricColType',
      },
      {
        colId: ColumnId.RPC,
        headerName: 'RPC',
        field: 'rpc',
        type: 'profileStatsMetricColType',
      },
      {
        colId: ColumnId.CPA,
        headerName: 'CPA',
        field: 'cpa',
        type: 'profileStatsMetricColType',
      },
      {
        colId: ColumnId.AOV,
        headerName: 'AOV',
        field: 'aov',
        type: 'profileStatsMetricColType',
      },
      {
        colId: ColumnId.CPM,
        headerName: 'CPM',
        field: 'cpm',
        type: 'profileStatsMetricColType',
      },
    ];

    if (Environment.isDev()) {
      if (hasSellerProfiles || hasVendorProfiles) {
        colDefs.push(
          {
            colId: ColumnId.ACOTS,
            headerName: 'ACOTS',
            field: 'seller_acots',
            type: 'profileStatsMetricColType',
          },
          {
            colId: ColumnId.ASP,
            headerName: 'ASP',
            field: 'seller_asp',
            type: 'profileStatsMetricColType',
          },
          {
            colId: ColumnId.ORGANIC_SALES,
            headerName: 'Organic Sales',
            field: 'seller_org_sales',
            type: 'profileStatsMetricColType',
          },
          {
            colId: ColumnId.TOTAL_VIEWS,
            headerName: 'Detail Page Views',
            field: 'seller_views',
            type: 'profileStatsMetricColType',
          },
          {
            colId: ColumnId.TOTAL_UNITS,
            headerName: 'Total Units',
            field: 'seller_units',
            type: 'profileStatsMetricColType',
          },
          {
            colId: ColumnId.TOTAL_SALES,
            headerName: 'Total Sales',
            field: 'seller_sales',
            type: 'profileStatsMetricColType',
          },
        );
      }

      if (hasSellerProfiles) {
        colDefs.push(
          {
            colId: ColumnId.USP,
            headerName: 'USP',
            field: 'seller_unit_sess',
            type: 'profileStatsMetricColType',
          },
          {
            colId: ColumnId.TOTAL_CVR,
            headerName: 'Total CVR',
            field: 'seller_tcvr',
            type: 'profileStatsMetricColType',
          },
          {
            colId: ColumnId.TOTAL_CLICKS,
            headerName: 'Total Clicks',
            field: 'seller_clicks',
            type: 'profileStatsMetricColType',
          },
          {
            colId: ColumnId.TOTAL_ORDERS,
            headerName: 'Total Orders',
            field: 'seller_orders',
            type: 'profileStatsMetricColType',
          },
        );
      }
    }

    applyStateToDefinitions(colDefs);

    return colDefs;
  }, []);

  const profileStatsMetricColType = {
    autoHeight: true,
    width: 125,
    headerComponent: ChangePercentageHeaderRenderer,
    headerComponentParams: (params: IHeaderParams<ProfileWithMetricsModel, ProfilesStatsGridContext>) =>
      getChangePercentageHeaderRendererParams(params, { customCurrencyCode: AGGREGATE_CURRENCY_CODE }),
    comparator: metricsDataComparator,
    //aggFunc: 'metricsDataAggFunc', // TODO: make custom
    cellRenderer: ChangePercentageCellRenderer,
    cellRendererParams: (params: ICellRendererParams<ProfileWithMetricsModel, unknown, ProfilesStatsGridContext>) =>
      getMetricFieldChangePercentageCellRendererParams(params, {
        customCurrencyCode: params.data?.currencyCode || AGGREGATE_CURRENCY_CODE,
      }),
    valueFormatter: (params: ValueFormatterParams) => {
      if (Array.isArray(params.value) && params.value.length > 0) {
        // Convert to string to avoid 0 values to be discarded and whole array returned on export
        return params.value[0].toString();
      }
      return '';
    },
  };

  // FILTERS
  const applyExternalFilters = (filters: AlFilterModel[]) => {
    if (gridApiRef.current) {
      gridApiRef.current.setGridOption('isExternalFilterPresent', () => true);
      gridApiRef.current.setGridOption('doesExternalFilterPass', (node: IRowNode<ProfileWithMetricsModel>) => {
        if (!node || !node.data || !filters || filters.length === 0) {
          return true;
        }

        return !filters.some((filter) => {
          if (!node.data) {
            return true;
          }
          return !filter.doesFilterPass(node.data);
        });
      });
      gridApiRef.current.onFilterChanged();
      gridApiRef.current.deselectAll();

      updateContextAggregates();
    }
  };

  useEffect(() => {
    applyExternalFilters(externalFilters);
  }, [externalFilters]);

  const customGridOptions: GridOptions<ProfileWithMetricsModel> = {
    ...DEFAULT_GRID_OPTIONS,
    getRowId: (params) => params.data.teamId.toString() + '-' + params.data.id.toString(),
    context: {
      comparisonUnit: comparisonUnit,
      metricColumnAggregates: metricColumnAggregates,
    },
    defaultColDef: {
      resizable: true,
      sortable: true,
      minWidth: 100,
      headerComponent: DefaultHeaderRenderer,
      sortingOrder: GRID_DEFAULT_SORTING_ORDER,
    },
    onColumnMoved: handleColumnStateChange,
    onColumnVisible: handleColumnStateChange,
    onColumnResized: handleColumnStateChange,
    onColumnRowGroupChanged: handleColumnStateChange,
    onSortChanged: handleColumnStateChange,
    onColumnPinned: handleColumnStateChange,
    columnTypes: {
      profileStatsMetricColType,
    },
    getRowStyle: (params) => {
      if (params.node.isRowPinned()) {
        return {
          background: 'linear-gradient(to bottom, rgba(96,165,250, 0.05), rgba(96,165,250, 0.2))',
        };
      }
    },
  };

  const onGridReady = (params: GridReadyEvent<ProfileWithMetricsModel>) => {
    setColumnStateGridApi(params.api);
    gridApiRef.current = params.api;
    gridContextRef.current = params.context;
    onGridReadyCallback?.(params);
    applyExternalFilters(externalFilters);
    onGridReadyForMetricColumnAggregates();
  };

  useEffect(() => {
    setIsAutoSaveEnabled(true);
  }, []);

  useEffect(() => {
    updateContextAggregates();
  }, [rowData]);
  return (
    <div className="flex flex-col gap-y-3 flex-1 flex-grow h-full">
      <AlGrid
        colDefs={columnDefs}
        rowData={rowData}
        gridOptions={customGridOptions}
        isLoading={isFetching}
        onGridReadyCallback={onGridReady}
      />
    </div>
  );
};

export default ProfilesStatsTable;
