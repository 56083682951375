import ErrorLoadingDataAlert from '@/components/feedback/ErrorLoadingDataAlert';
import LoadingBlock from '@/components/feedback/LoadingBlock';
import { useDeleteConfirmation } from '@/components/modals/delete-confirmation-modal/useDeleteConfirmationModal';
import { useFullscreenToggle } from '@/hooks/useFullscreenToggle';
import { PageLayoutBody, PageLayoutTopBar } from '@/modules/application';
import DateRangePickerButton from '@/modules/application/components/date-range-picker/DateRangePickerButton';
import { PageLayout } from '@/modules/application/layouts/PageLayout';
import { ArrowDropDown, Close, Edit } from '@mui/icons-material';
import { ButtonBase, ButtonGroup, IconButton, Menu, MenuItem, Skeleton } from '@mui/material';
import dayjs, { Dayjs } from 'dayjs';
import { isNil } from 'lodash-es';
import { FunctionComponent, useEffect, useMemo, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import { v4 } from 'uuid';
import { dashboardService } from '../api/dashboard/dashboard.service';
import Dashboard from '../components/dashboard/Dashboard';
import RenameDashboardDialog from '../components/dashboard/RenameDasbhoardDialog';
import { DashboardContext, useDashboard } from '../contexts/DashboardContextProvider';
import EditableText from '@/components/form/EditableText';
import { useNavigationConfirmationModal } from '@/components/modals/confirmation-modal/useNavigationConfirmationModal';
import { useQuery } from '@tanstack/react-query';
import { DashboardCalculatedDataModel } from '../types/DashboardCalculatedDataModel';
import { generateDashboardQueryKey } from '../hooks/useDashboardQueries';
import DashboardHeaderActionBar from '../components/dashboard/DashboardHeaderActionBar';
import DashboardProfilesPopover from '../components/dashboard/DashboardProfilesPopover';
import { TeamProfile } from '../types/TeamProfile';
import AlErrorBoundary from '@/components/feedback/AlErrorBoundary';

export const DashboardPage: FunctionComponent = () => {
  const { dashboardId } = useParams();

  const [isDashboardFullscreen, toggleIsDashboardFullscreen] = useFullscreenToggle();

  const {
    setPlaceholderSize,
    widgetPlaceholderHeight,
    widgetPlaceholderWidth,
    editingWidgetId,
    setEditingWidgetId,
    isEditingDashboard,
    setIsEditingDashboard,
    widgets,
    setWidgets,
    dashboard,
    isLoadingDashboard,
    removeWidget,
    updateWidgetConfiguration,
    dates,
    setDates,
    comparisonDates,
    setComparisonDates,
    dashboardDateFilters,
    setDragginWidgetId,
    dragginWidgetId,
    addWidget,
    saveDashboard,
    setWidgetIdsRequestingData,
    widgetIdsRequestingData,
    updateWidgetLayout,
    setDashboardVersion,
    dashboardVersion,
    removeAllWidgets,
  } = useDashboard(dashboardId);

  const dashboardContextValue = useMemo(
    () => ({
      setPlaceholderSize,
      widgetPlaceholderHeight,
      widgetPlaceholderWidth,
      editingWidgetId,
      setEditingWidgetId,
      isEditingDashboard,
      setIsEditingDashboard,
      widgets,
      setWidgets,
      dashboard,
      isLoadingDashboard,
      removeWidget,
      updateWidgetConfiguration,
      dates,
      setDates,
      comparisonDates,
      setComparisonDates,
      dashboardDateFilters,
      setDragginWidgetId,
      dragginWidgetId,
      addWidget,
      saveDashboard,
      setWidgetIdsRequestingData,
      widgetIdsRequestingData,
      updateWidgetLayout,
      setDashboardVersion,
      dashboardVersion,
      removeAllWidgets,
    }),
    [
      setPlaceholderSize,
      widgetPlaceholderHeight,
      widgetPlaceholderWidth,
      editingWidgetId,
      setEditingWidgetId,
      isEditingDashboard,
      setIsEditingDashboard,
      widgets,
      setWidgets,
      dashboard,
      isLoadingDashboard,
      removeWidget,
      updateWidgetConfiguration,
      dates,
      setDates,
      comparisonDates,
      setComparisonDates,
      dashboardDateFilters,
      setDragginWidgetId,
      dragginWidgetId,
      removeAllWidgets,
    ],
  );

  const { ModalComponent: navigationModal, setBlock } = useNavigationConfirmationModal({
    questionText: 'Are you sure you want to leave this page?',
  });

  useEffect(() => {
    if (isEditingDashboard) {
      setBlock(true);
    } else {
      setBlock(false);
    }
  }, [isEditingDashboard]);

  const navigate = useNavigate();

  const { ModalComponent, handleOpenModal } = useDeleteConfirmation({
    questionText: 'Are you sure you want to delete this dashboard?',
    headerText: 'Delete Dashboard',
    onDelete: deleteDashboard,
  });

  function onDeleteDashboardClicked() {
    handleOpenModal();
  }

  async function deleteDashboard() {
    if (!dashboardId) return;

    const result = await dashboardService.delete(+dashboardId);
    if (result.isSuccess) {
      toast.success('Dashboard deleted');
      navigate('/dashboards', { replace: true });
    }
  }

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  function onRenameDashboardClicked() {
    setIsRenameDashboardDialogOpen(true);
  }

  const [isRenameDashboardDialogOpen, setIsRenameDashboardDialogOpen] = useState(false);

  function onDashboardRenamed() {
    setIsRenameDashboardDialogOpen(false);
  }

  async function onSetDates(dates: Dayjs[], comparisonDates: Dayjs[]) {
    setDates(dates);
    setComparisonDates(comparisonDates);
    if (dashboardId) {
      if (dashboard) {
        dashboard.dto.filter_start_date_offset_in_days = dates[0].diff(dayjs(), 'day');
        dashboard.dto.filter_end_date_offset_in_days = dates[1].diff(dayjs(), 'day');
        dashboard.dto.filter_compare_start_date_offset_in_days = comparisonDates[0].diff(dayjs(), 'day');
        dashboard.dto.filter_compare_end_date_offset_in_days = comparisonDates[1].diff(dayjs(), 'day');
      }

      await dashboardService.update(+dashboardId, {
        filter_start_date_offset_in_days: dates[0].diff(dayjs(), 'day'),
        filter_end_date_offset_in_days: dates[1].diff(dayjs(), 'day'),
        filter_compare_start_date_offset_in_days: comparisonDates[0].diff(dayjs(), 'day'),
        filter_compare_end_date_offset_in_days: comparisonDates[1].diff(dayjs(), 'day'),
      });

      setDashboardVersion(v4());
    }
  }

  useEffect(() => {
    if (isLoadingDashboard) return;
    if (!dashboard) return;

    if (isNil(dashboard.dto.filter_start_date_offset_in_days)) return;
    if (isNil(dashboard.dto.filter_end_date_offset_in_days)) return;
    if (isNil(dashboard.dto.filter_compare_start_date_offset_in_days)) return;
    if (isNil(dashboard.dto.filter_compare_end_date_offset_in_days)) return;

    setDates([
      dayjs().add(dashboard.dto.filter_start_date_offset_in_days, 'day'),
      dayjs().add(dashboard.dto.filter_end_date_offset_in_days, 'day'),
    ]);
    setComparisonDates([
      dayjs().add(dashboard.dto.filter_compare_start_date_offset_in_days, 'day'),
      dayjs().add(dashboard.dto.filter_compare_end_date_offset_in_days, 'day'),
    ]);
  }, [isLoadingDashboard]);

  const { data: dashboardData } = useQuery<DashboardCalculatedDataModel, Error, DashboardCalculatedDataModel, (string | number)[]>({
    queryKey: generateDashboardQueryKey(dashboard),
    enabled: false,
  });

  // TODO: Add error state for when dashboard is not found
  if (dashboardId && !dashboard) {
    return (
      <div className="h-full max-h-screen min-h-screen">
        <LoadingBlock />
      </div>
    );
  }

  async function onDashboardNameChanged(newDashboardName: string) {
    if (dashboard) {
      const renamedDashboard = await dashboardService.update(dashboard.dto.id, { name: newDashboardName });
      if (renamedDashboard.isSuccess) {
        dashboard.dto.name = renamedDashboard.payload.name;
        toast.success('Dashboard renamed');
      }
    }
  }

  async function onSelectedProfilesChanged(selectedProfiles: TeamProfile[]) {
    if (dashboard) {
      const updatedDashboard = await dashboardService.updateDashboardProfiles(
        dashboard.dto.id,
        selectedProfiles.map((p) => {
          return {
            profile_id: p.profileId.toString(),
            team_id: p.teamId,
          };
        }),
      );
      if (updatedDashboard.isSuccess) {
        dashboard.dto.profiles = updatedDashboard.payload.profiles;
        toast.success('Profiles updated');
      } else {
        toast.error('Error updating profiles');
      }
    }
  }

  return (
    <DashboardContext.Provider value={dashboardContextValue}>
      <PageLayout showFullscreen={isDashboardFullscreen}>
        {isDashboardFullscreen ? (
          <div className="flex w-full items-center justify-between px-4">
            <div>
              {dashboard ? (
                <div className="text-2xl font-semibold">{dashboard.dto.name}</div>
              ) : (
                <div className="text-2xl font-semibold">Dashboard</div>
              )}
            </div>

            <div>
              <IconButton
                onClick={() => {
                  toggleIsDashboardFullscreen();
                }}
              >
                <Close />
              </IconButton>
            </div>
          </div>
        ) : (
          ''
        )}
        <PageLayoutTopBar
          header={
            dashboard ? (
              <div className="flex">
                <EditableText onTextChange={onDashboardNameChanged} text={dashboard.dto.name} />
                <div className="px-1">-</div>
                <DashboardProfilesPopover dashboard={dashboard} onSelectionChange={onSelectedProfilesChanged} />
              </div>
            ) : (
              'Dashboard'
            )
          }
          navigateBackPathname="/dashboards"
          actions={
            <>
              {!dashboard || isLoadingDashboard ? (
                <Skeleton className="rounded-xl" variant="rectangular" animation="wave" width={200} height={35} />
              ) : (
                <div className="bg-white p-0.5 rounded-md">
                  <DateRangePickerButton
                    comparisonRangeWarningText={
                      dashboardData && dashboardData.dto.comparison_missing
                        ? 'Comparison data is missing for the selected date range'
                        : undefined
                    }
                    dates={dates}
                    comparisonDates={comparisonDates}
                    onSetDates={onSetDates}
                    disablePaywall
                  />
                </div>
              )}
              <ButtonGroup variant="contained" disableElevation color="secondary" className="">
                <ButtonBase
                  className={`min-w-32 h-10 flex cursor-pointer justify-center rounded-l-md p-2 text-base font-medium text-gray-850 bg-white hover:bg-primary-50 hover:text-gray-700 dark:text-gray-300 dark:hover:bg-gray-700`}
                  aria-roledescription="button"
                  role="button"
                  tabIndex={0}
                  onClick={() => {
                    setIsEditingDashboard(!isEditingDashboard);
                  }}
                >
                  <Edit fontSize="small" className="pr-1" />
                  Edit
                </ButtonBase>
                <div className="flex h-full w-px bg-slate-400"></div>
                <ButtonBase
                  className={`min-w-8 h-10 flex cursor-pointer justify-center rounded-r-md p-2 font-semibold text-gray-850 bg-white hover:bg-primary-50 hover:text-gray-700 dark:text-gray-300 dark:hover:bg-gray-700`}
                  aria-roledescription="button"
                  role="button"
                  tabIndex={1}
                  onClick={handleClick}
                >
                  <ArrowDropDown />
                </ButtonBase>
              </ButtonGroup>
              <Menu anchorEl={anchorEl} open={open} onClose={handleClose}>
                <MenuItem
                  onClick={() => {
                    onRenameDashboardClicked();
                    handleClose();
                  }}
                >
                  Rename Dashboard
                </MenuItem>
                <MenuItem
                  onClick={() => {
                    onDeleteDashboardClicked();
                    handleClose();
                  }}
                >
                  Delete Dashboard
                </MenuItem>
              </Menu>
            </>
          }
        />

        <PageLayoutBody>
          {isLoadingDashboard ? (
            <LoadingBlock />
          ) : !dashboard ? (
            <div className="mt-4">
              <ErrorLoadingDataAlert />
            </div>
          ) : (
            <AlErrorBoundary>
              <div
                className={`absolute bottom-0 left-0 right-0 top-0 h-full overflow-auto scrollbar scrollbar-track-gray-200 scrollbar-thumb-gray-300 dark:scrollbar-track-gray-700 dark:scrollbar-thumb-gray-950`}
              >
                <div className="pb-4">{widgets && <Dashboard></Dashboard>}</div>
              </div>
              <div className={'w-96'}></div>
              <DashboardHeaderActionBar
                onLeaveEditMode={() => setIsEditingDashboard(false)}
                isEditingDashboard={isEditingDashboard}
                setPlaceholderSize={setPlaceholderSize}
              />
            </AlErrorBoundary>
          )}
        </PageLayoutBody>
      </PageLayout>

      {dashboard !== undefined && isRenameDashboardDialogOpen && (
        <RenameDashboardDialog
          isOpen={isRenameDashboardDialogOpen}
          onClose={() => setIsRenameDashboardDialogOpen(false)}
          onDashboardRenamed={onDashboardRenamed}
          dashboard={dashboard}
        />
      )}
      {ModalComponent}
      {navigationModal}
    </DashboardContext.Provider>
  );
};
