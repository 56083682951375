import { UnitType } from '@/components/filter-builder/models/AlFilterModel';
import useColDefsFunctions from '@/components/grid/hooks/useColDefsFunctions';
import useMetricColumnAggregates from '@/components/grid/hooks/useMetricColumnAggregates';
import { ExpandedGridContext } from '@/components/grid/types';
import { METRICS, getMetricColumns } from '@/components/metrics/MetricsConfig';
import { MetricData } from '@/components/metrics/types/MetricData';
import { MetricAggregates } from '@/components/metrics/types/MetricField';
import useCurrency from '@/modules/currency/hooks/useCurrency';
import { ProfileWithMetricsModel } from '@/modules/profiles/types/ProfileWithMetricsModel';
import { CurrencyCode } from '@/modules/users/types/CurrencyCode';
import { GridApi } from 'ag-grid-enterprise';
import { useMemo, useState } from 'react';

interface UseProfileStatsMetricColumnAggregatesProps {
  gridApiRef: React.MutableRefObject<GridApi<ProfileWithMetricsModel> | undefined>;
  gridContextRef: React.MutableRefObject<ExpandedGridContext | undefined>;
  aggDataCurrencyCode: CurrencyCode; // Not dynamic as it's not in the context
}

const useProfileStatsMetricColumnAggregates = ({
  gridApiRef,
  gridContextRef,
  aggDataCurrencyCode,
}: UseProfileStatsMetricColumnAggregatesProps) => {
  const { getCalculatedMetricAggData } = useColDefsFunctions();
  const { conversionRatesModel } = useCurrency();

  const [updateTrigger, setUpdateTrigger] = useState(0);
  function updateContextAggregates() {
    if (!gridApiRef.current || !gridContextRef.current) return;
    setUpdateTrigger((prev) => prev + 1);
  }

  // TOTALS ROW
  const getMetricColumnAggregates = (): MetricAggregates | undefined => {
    if (!gridApiRef.current || !conversionRatesModel) return;

    const colIdToConfigRecord = Object.values(METRICS).reduce(
      (acc, metricConfig) => {
        acc[metricConfig.colId] = metricConfig;
        return acc;
      },
      {} as Record<string, (typeof METRICS)[keyof typeof METRICS]>,
    );

    const aggData: Record<string, MetricData> = {};

    // Get all nodes that pass the current filter
    // Non-calculated metrics
    gridApiRef.current.forEachNodeAfterFilter((node) => {
      if (!node.data || !gridApiRef.current) return;

      for (const colId of getMetricColumns()) {
        const config = colIdToConfigRecord[colId];
        const field = gridApiRef.current.getColumnDef<ProfileWithMetricsModel>(colId)?.field;
        if (config.isCalculatedMetric || !field) continue;

        const fieldValue = node.data[field as keyof ProfileWithMetricsModel] as MetricData | undefined;
        if (!fieldValue) continue;

        // Aggregate (sum up) non-calculated metrics
        let fieldCurrentValue = fieldValue[0];
        let fieldPreviousValue = fieldValue[1];

        if (config.unitType === UnitType.CURRENCY) {
          fieldCurrentValue = conversionRatesModel.convertValueFromTo(fieldValue[0], node.data.currencyCode, aggDataCurrencyCode);
          fieldPreviousValue = conversionRatesModel.convertValueFromTo(fieldValue[1], node.data.currencyCode, aggDataCurrencyCode);
        }

        const accumulatorValue = aggData[config.key] ?? [0, 0];
        const newValue: MetricData = [accumulatorValue[0] + fieldCurrentValue, accumulatorValue[1] + fieldPreviousValue];

        aggData[config.key] = newValue;
      }
    });

    // Data not there yet
    if (Object.keys(aggData).length === 0) return;

    // Once non-calculated metrics are summed up, find calculated metrics
    for (const colId of getMetricColumns()) {
      const config = colIdToConfigRecord[colId];
      const field = gridApiRef.current.getColumnDef<ProfileWithMetricsModel>(colId)?.field;
      if (!config.isCalculatedMetric || !field) continue;

      const newValue = getCalculatedMetricAggData(config.key, aggData);
      aggData[config.key] = newValue;
    }

    return Object.fromEntries(Object.entries(aggData).map(([key, value]) => [key, { current: value[0] }])) as MetricAggregates;
  };

  const metricColumnAggregates = useMemo(() => getMetricColumnAggregates(), [conversionRatesModel, updateTrigger]);

  const { onGridReadyForMetricColumnAggregates } = useMetricColumnAggregates({
    gridApiRef,
    gridContextRef,
    metricColumnAggregates,
  });

  return { metricColumnAggregates, onGridReadyForMetricColumnAggregates, updateContextAggregates };
};

export default useProfileStatsMetricColumnAggregates;
